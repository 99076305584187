/* common */
.loader {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
}

.loading {
    width: 50vh;
    height: 20vh;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25vh;
    /* half width*/
    margin-top: -10vh;
    /* half height*/
    font-size: 4em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-align: center;
}

.loading span {
    display: inline-block;
    margin: 0 -0.05em;
}

/* code #1 */
.loading01 span {
    animation: loading01 1.4s infinite alternate;
}

.loading01 span:nth-child(1) {
    animation-delay: 0s;
}

.loading01 span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading01 span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading01 span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading01 span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading01 span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading01 span:nth-child(7) {
    animation-delay: 0.6s;
}

@keyframes loading01 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* code #2 */
.loading02 span {
    animation: loading02 1.2s infinite alternate;
}

.loading02 span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading02 span:nth-child(3) {
    animation-delay: 0.4s;
}

.loading02 span:nth-child(4) {
    animation-delay: 0.6s;
}

.loading02 span:nth-child(5) {
    animation-delay: 0.8s;
}

.loading02 span:nth-child(6) {
    animation-delay: 1s;
}

.loading02 span:nth-child(7) {
    animation-delay: 1.2s;
}

@keyframes loading02 {
    0% {
        filter: blur(0);
        opacity: 1;
    }

    100% {
        filter: blur(5px);
        opacity: 0.2;
    }
}

/* code #3 */
.loading03 span {
    margin: 0 -0.075em;
    animation: loading03 0.7s infinite alternate;
}

.loading03 span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading03 span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading03 span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading03 span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading03 span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading03 span:nth-child(7) {
    animation-delay: 0.6s;
}

@keyframes loading03 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

/* code #4 */
.loading04 span {
    animation: loading04 0.7s infinite;
}

.loading04 span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading04 span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading04 span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading04 span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading04 span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading04 span:nth-child(7) {
    animation-delay: 0.6s;
}

@keyframes loading04 {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(15px);
    }
}

/* code #5 */
.loading05 {
    perspective: 1000px;
}

.loading05 span {
    transform-origin: 50% 50% -25px;
    transform-style: preserve-3d;
    animation: loading05 1.6s infinite;
}

.loading05 span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading05 span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading05 span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading05 span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading05 span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading05 span:nth-child(7) {
    animation-delay: 0.6s;
}

@keyframes loading05 {
    0% {
        transform: rotateX(-360deg);
    }

    70% {
        transform: rotateX(0);
    }
}

/* code #6 */
.loading06 span {
    position: relative;
    color: rgba(0, 0, 0, 0.2);
}

.loading06 span::after {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-text);
    color: #fff;
    opacity: 0;
    transform: rotateY(-90deg);
    animation: loading06 4s infinite;
}

.loading06 span:nth-child(2)::after {
    animation-delay: 0.2s;
}

.loading06 span:nth-child(3)::after {
    animation-delay: 0.4s;
}

.loading06 span:nth-child(4)::after {
    animation-delay: 0.6s;
}

.loading06 span:nth-child(5)::after {
    animation-delay: 0.8s;
}

.loading06 span:nth-child(6)::after {
    animation-delay: 1s;
}

.loading06 span:nth-child(7)::after {
    animation-delay: 1.2s;
}

@keyframes loading06 {

    0%,
    75%,
    100% {
        transform: rotateY(-90deg);
        opacity: 0;
    }

    25%,
    50% {
        transform: rotateY(0);
        opacity: 1;
    }
}

/* code #7 */
.loading07 span {
    position: relative;
    color: rgba(0, 0, 0, 0.2);
}

.loading07 span::after {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-text);
    color: #fff;
    opacity: 0;
    transform: scale(1.5);
    animation: loading07 3s infinite;
}

.loading07 span:nth-child(2)::after {
    animation-delay: 0.1s;
}

.loading07 span:nth-child(3)::after {
    animation-delay: 0.2s;
}

.loading07 span:nth-child(4)::after {
    animation-delay: 0.3s;
}

.loading07 span:nth-child(5)::after {
    animation-delay: 0.4s;
}

.loading07 span:nth-child(6)::after {
    animation-delay: 0.5s;
}

.loading07 span:nth-child(7)::after {
    animation-delay: 0.6s;
}

@keyframes loading07 {

    0%,
    75%,
    100% {
        transform: scale(1.5);
        opacity: 0;
    }

    25%,
    50% {
        transform: scale(1);
        opacity: 1;
    }
}

/* code #8 */
.loading08 span {
    position: relative;
    color: rgba(0, 0, 0, 0.2);
    animation: loading08-parent 5s infinite;
}

.loading08 span::after {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-text);
    color: #fff;
    opacity: 0;
    animation: loading08-child 5s infinite;
}

.loading08 span:nth-child(2)::after {
    animation-delay: 0.2s;
}

.loading08 span:nth-child(3)::after {
    animation-delay: 0.4s;
}

.loading08 span:nth-child(4)::after {
    animation-delay: 0.6s;
}

.loading08 span:nth-child(5)::after {
    animation-delay: 0.8s;
}

.loading08 span:nth-child(6)::after {
    animation-delay: 1s;
}

.loading08 span:nth-child(7)::after {
    animation-delay: 1.2s;
}

@keyframes loading08-parent {

    0%,
    35%,
    100% {
        color: rgba(0, 0, 0, 0.2);
    }

    60%,
    80% {
        color: #fff;
    }
}

@keyframes loading08-child {
    0% {
        opacity: 1;
    }

    25%,
    100% {
        opacity: 0;
    }
}