#banner {
    position: absolute;
    width: 99vw;
    height: 10vh;
    background-color: transparent;
    bottom: 0;
    z-index: 999;
    display: none;
    pointer-events: none;
}

@keyframes ad-banner-raiseBanner {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes ad-banner-dropBanner {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.ad-banner {
    position: fixed;
    bottom: 0;
    display: block;
    width: 100%;
    height: fit-content;
    z-index: 80;
    background-color: #06376A;
    background-image: linear-gradient(#06376A, #084383);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-decoration: none;
    animation-name: ad-banner-raiseBanner;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.17, 0.04, 0.03, 0.94);
    animation-duration: 0.8s;
}

.ad-banner,
.ad-banner * {
    box-sizing: border-box;
}



.ad-banner:before {
    position: absolute;
    top: -14px;
    display: block;
    width: 100%;
    height: 14px;
    content: "";
    background-color: #06376A;
}

.ad-banner-wrap {
    display: flex;
    max-width: 49em;
    margin: 0 auto;
    padding: 0.5em 1em;
    justify-content: space-around;
    align-items: center;
}

.ad-banner-text {
    margin: 0 2em 0 0;
    line-height: 1.35;
    color: #fff;
    text-shadow: 0 0 1px #999;
}

.ad-banner-brand {
    text-transform: uppercase;
    font-weight: 700;
}

.ad-banner-cta {
    flex: 0 0 14em;
    padding: 0.8em 1em;
    border: none;
    border-radius: 2px;
    background: #2f2f2f;
    font-size: 0.8125em;
    font-style: italic;
    font-weight: 700;
    line-height: 1.35;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    transition: background 0.5s ease;
}

@media (min-width: 780px) {
    .ad-banner {
        font-size: 16px;
    }
}

a {
    text-decoration: none;
    color: white;
}